import { ENDPOINTS } from "../../../common/service/config/endpoint";
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  makeUnauthorizedRequestWithHeadersAndPayload,
  queryHandler,
} from "../../../common/service/request";
import * as tp from "../types";

const service = {
  async inviteUser(data: tp.IinviteMember) {
    const { method, url } = ENDPOINTS.user.invite_member;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async acceptInvite(
    data: tp.IacceptMemberInvite
  ): Promise<tp.APIResponseModel<tp.AuthSuccessRes>> {
    const { method, url } = ENDPOINTS.user.accept_invite;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async inviteOtp() {
    const { method, url } = ENDPOINTS.user.resend_otp;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async resendInvitation(data: tp.IresendInvite) {
    const { method, url } = ENDPOINTS.user.resend_invitation;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async rescindInvitation(data: tp.IresendInvite) {
    const { method, url } = ENDPOINTS.user.rescind_invitation;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async verifyAccount(data: tp.IverifyAccount) {
    const { method, url } = ENDPOINTS.user.verify_account;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async updateAccountStatus(req: tp.IupdateAccount) {
    const { method, url: makeUrl } = ENDPOINTS.user.update_account_status;
    const url = makeUrl(req.member_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, req.data);
  },
  async updateAccountType(req: tp.IupdateAccount) {
    const { method, url: makeUrl } = ENDPOINTS.user.update_account_type;
    const url = makeUrl(req.member_id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, req.data);
  },
  async getUserList(
    req: tp.Ipaginated
  ): Promise<tp.APIResponseModel<tp.User[]>> {
    const { method, url: makeUrl } = ENDPOINTS.user.list_members;
    const query = queryHandler(req);
    const url = makeUrl(query);

    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
