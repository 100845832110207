import React, { memo } from "react";
import {
  useAppSelector,
  useCopyToClipboard,
} from "../../hooks";
import { BsCheckAll } from "react-icons/bs";
import { CopyIcon } from "../../../static/images/icons";
import useSessionRefresh from "@/common/hooks/useSessionRefresh";

type Props = {
  className?: string;
};

const MultiUserId = memo(({ className }: Props) => {
  const auth = useAppSelector((state) => state.auth);
  const { email, company } = auth.user;
  const admin_approved = auth.company?.admin_approved;
  const { copyToClipboard, isCopied } = useCopyToClipboard();
  useSessionRefresh();

  const handleCopyClick = () => {
    copyToClipboard(company);
  };

  return (
    <>
      {admin_approved ? (
        <div className={className}>
          <p className="mx-auto w-60 overflow-hidden truncate text-ellipsis text-center text-sm">
            {email}
          </p>
          <div>
            <button
              onClick={handleCopyClick}
              className="inline-flex items-center gap-x-1"
            >
              <span className="text-917">MID: {company}</span>
              {isCopied ? (
                <BsCheckAll className="animate-pulse text-sm text-green-10" />
              ) : (
                <span className="fill-inherit text-xl">
                  <CopyIcon scale={0.4} />
                </span>
              )}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
});

export default MultiUserId;
