import { ENDPOINTS } from "../../../common/service/config/endpoint";
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  makeUnauthorizedRequestWithHeadersAndPayload,
} from "../../../common/service/request";
import {
  APIResponseModel,
  APIResponseSuccessModel,
  AuthSuccessRes,
  I2faToken,
  Iemail,
  IloginUser,
  Irefresh,
  IregisterUser,
  IresetPassword,
  IverifyAccount,
  UserTokenType,
} from "../type";

const auth = {
  async registerUser(
    data: IregisterUser
  ): Promise<APIResponseModel<AuthSuccessRes>> {
    const { method, url } = ENDPOINTS.auth.signup;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async emailValidate(data: Iemail): Promise<APIResponseSuccessModel> {
    const { method, url } = ENDPOINTS.auth.email_validate;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async loginUser(data: IloginUser): Promise<APIResponseModel<AuthSuccessRes>> {
    const { method, url } = ENDPOINTS.auth.signin;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async logUserOut(data: any): Promise<APIResponseSuccessModel> {
    const { method, url } = ENDPOINTS.auth.signout;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async verified(data: IverifyAccount): Promise<APIResponseSuccessModel> {
    const { method, url } = ENDPOINTS.auth.verified;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async resetPassword(data: Iemail): Promise<APIResponseSuccessModel> {
    const { method, url } = ENDPOINTS.auth.initiate_reset_password;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async validateResetPassword(
    data: IresetPassword
  ): Promise<APIResponseSuccessModel> {
    const { method, url } = ENDPOINTS.auth.validate_reset_password;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async resendToken(data: Iemail): Promise<APIResponseSuccessModel> {
    const { method, url } = ENDPOINTS.auth.resend_token;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async refreshToken(data: Irefresh): Promise<APIResponseModel<UserTokenType>> {
    const { method, url } = ENDPOINTS.auth.refresh_token;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getCountries(): Promise<APIResponseSuccessModel> {
    const { method, url } = ENDPOINTS.auth.get_country;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url);
  },
  async send2faVerificationToken(
    data: Iemail
  ): Promise<APIResponseSuccessModel> {
    const { method, url } = ENDPOINTS.auth.send_2fa_verification_token;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async verify2faToken(
    data: I2faToken
  ): Promise<APIResponseModel<AuthSuccessRes>> {
    const { method, url } = ENDPOINTS.auth.verify_2fa_code;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, data);
  },
};

export default auth;
