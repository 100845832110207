import React from "react";
import CustomInput from "../../../../../../common/components/CustomInput";
import SideModal from "../../../../../../common/components/CustomModal/SideModal";
import ClipboardCopy from "../../../../../../common/components/ClipboardCopy";
import CustomButton from "../../../../../../common/components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../../../../../common/routes";
import ModalTitleHeader from "../../../../../../common/components/ModalTitleHeader";
import { useAppSelector } from "../../../../../../common/hooks";
import ModalNavLinks from "../../../../components/ModalNavLinkFunding";
import Language from "../../../../../../common/utils/language/en";

const { FundCryptoForm: PageDictionary } =
  Language.protected.walletModals.funding;

function FundCryptoForm() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useAppSelector((state) => state.wallet.app_wallet);

  const backDropHandler = () => {
    if (appid) {
      const path = routes.protected.applications.app.wallet.link(appid);
      navigate(path, { replace: true });
    }
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    backDropHandler();
  };

  return (
    <SideModal
      className="p-8 md:pt-22 md:pl-15 md:pr-28"
      onclickBackDrop={backDropHandler}
    >
      <ModalTitleHeader
        title={PageDictionary.header.title}
        subtitle={PageDictionary.header.subtitle}
      />
      <form onSubmit={submitHandler} className="flex flex-col gap-y-5 pt-9">
        <ModalNavLinks />
        <CustomInput
          label={PageDictionary.form.coin_label}
          value={data?.crypto_symbol ? data?.crypto_symbol : ""}
          readOnly
        />

        <div className="pt-9">
          <p className="mb-6">{PageDictionary.form.address_label}</p>
          <ClipboardCopy
            walletAddress={data?.address ? data?.address : ""}
            loading={loading}
          />
        </div>
        <CustomButton className="mt-4 self-start rounded-lg py-3 px-12 text-sm leading-6">
          {PageDictionary.form.submit_btn}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default FundCryptoForm;
