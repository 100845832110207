import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../service";
import customToast from "../../../common/components/CustomToast";
import * as tp from "../types";

export const listUsers = createAsyncThunk(
  "user/listUsers",
  async (
    req: tp.Ipaginated,
    { rejectWithValue, fulfillWithValue, getState }
  ) => {
    return service
      .getUserList(req)
      .then((res) => {
        const temp: any = getState();
        const currentUser = temp?.auth?.user?.email;

        const resData = fulfillWithValue(res).payload;

        return {
          ...resData,
          data: resData.data.filter((itm) => itm.email !== currentUser),
        };
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const inviteUser = createAsyncThunk(
  "user/inviteUser",
  async (
    data: tp.IinviteMember,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    return service
      .inviteUser(data)
      .then((res) => {
        const temp: any = getState();
        const page = temp?.user?.members?.pagination?.current_page;
        const size = temp?.user?.members?.pagination?.pagesize;
        customToast.success(res.message);
        dispatch(listUsers({ page, page_size: size }));
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const acceptInvite = createAsyncThunk(
  "user/acceptInvite",
  async (
    data: tp.IacceptMemberInvite,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    return service
      .acceptInvite(data)
      .then((res) => {
        customToast.success(res.message);
        dispatch(inviteOtp);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const inviteOtp = createAsyncThunk(
  "user/inviteOtp",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    return service
      .inviteOtp()
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const resendInvitation = createAsyncThunk(
  "user/resendInvitation",
  async (data: tp.IresendInvite, { rejectWithValue, fulfillWithValue }) => {
    return service
      .resendInvitation(data)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const rescindInvitation = createAsyncThunk(
  "user/rescindInvitation",
  async (
    data: tp.IresendInvite,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    return service
      .rescindInvitation(data)
      .then((res) => {
        const temp: any = getState();
        const page = temp?.user?.members?.pagination?.current_page;
        const size = temp?.user?.members?.pagination?.pagesize;
        customToast.success(res.message);
        dispatch(listUsers({ page, page_size: size }));
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const verifyInviteAccount = createAsyncThunk(
  "user/verifyAccount",
  async (data: tp.IverifyAccount, { rejectWithValue, fulfillWithValue }) => {
    return service
      .verifyAccount(data)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const updateAccountStatus = createAsyncThunk(
  "user/updateAccountStatus",
  async (
    req: tp.IupdateAccount,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    return service
      .updateAccountStatus(req)
      .then((res) => {
        const temp: any = getState();
        const page = temp?.pagination?.current_page;
        const size = temp?.pagination?.pagesize;
        customToast.success(res.message);
        dispatch(listUsers({ page, page_size: size }));
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const updateAccountType = createAsyncThunk(
  "user/updateAccountType",
  async (
    req: tp.IupdateAccount,
    { rejectWithValue, fulfillWithValue, dispatch, getState }
  ) => {
    return service
      .updateAccountType(req)
      .then((res) => {
        const temp: any = getState();
        const page = temp?.pagination?.current_page;
        const size = temp?.pagination?.pagesize;
        customToast.success(res.message);
        dispatch(listUsers({ page, page_size: size }));
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
