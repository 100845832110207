import React, { useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../../../common/components/CustomButton";
import CustomToggle from "../../../../../common/components/CustomToggle";
import routes from "../../../../../common/routes";
import { goLive } from "../../../store/thunk";
import { IappMode } from "../../../types";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../../common/hooks";
import { toast } from "../../../../../common/components/CustomToast";
import { appActions } from "../../../store/slice";

export default function ApiSettingsLayout() {
  const { appid } = useParams();
  const [liveLoading, setLiveLoading] = useState(false);
  const appdata = useAppSelector((state) =>
    state.app.tabApps.find((app) => app._id === appid)
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    if (!appid || !appdata) return;
    if (toast.isActive("TEST-MODE")) {
      toast.dismiss("TEST-MODE");
    }

    navigate(
      routes.protected.applications.app.api_settings.shown_api_setting.link(
        appid
      ),
      {
        state: {
          background: location,
        },
        replace: true,
      }
    );

    return () => {
      dispatch(appActions.resetApiInfo());
    };
  });

  const toggleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!appid) return;
    setLiveLoading(true);

    const toggle_mode_to: IappMode = appdata?.mode === "LIVE" ? "TEST" : "LIVE";

    const req = { appid, data: { mode: toggle_mode_to } };
    dispatch(goLive(req))
      .unwrap()
      .finally(() => setLiveLoading(false));
  };

  const regerateKeyHandler = () => {
    if (!appid) return;
    const path =
      routes.protected.applications.app.api_settings.regen_api_keys.link(appid);
    navigate(path, { state: { background: location }, replace: true });
  };

  return (
    <div className="grid grid-cols-[1fr_10.25rem] gap-y-3 lg:pr-[13.75rem]">
      <div className="col-start-1 col-end-3 flex items-center justify-between md:col-end-2">
        <div className="flex items-center gap-x-20">
          <h4 className="text-2226 font-semibold">API Keys</h4>
          <CustomToggle
            check={appdata?.mode === "LIVE"}
            label="Live"
            className="gap-x-3"
            onToggle={toggleHandler}
            isLoading={liveLoading}
          />
        </div>
        <CustomButton
          type="button"
          className="h-10 py-2 px-6 text-sm leading-6"
          onClick={regerateKeyHandler}
        >
          Re-generate
        </CustomButton>
      </div>

      <div className="col-start-1 col-end-3 flex flex-col items-start gap-y-5 ">
        {/* <CustomInput
          label="Secret Key"
          name="secret_key"
          value={data.api_keys.secret}
          className="h-10"
          readOnly
        /> */}
        <p className="text-red-10">
          * On Click Of the regenrate button, A new Public and Secret key will
          be regenrated and downloaded
        </p>
      </div>
      <Outlet />
    </div>
  );
}
