import {
  clearUserDetails,
  fetchRefreshToken,
  fetchUserToken,
} from "../service/storage";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { decodeToken } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import routes from "../routes";
import { useAppDispatch } from "./redux";
import { refreshUserToken } from "@/module/auth/store/thunk";
import { useCountDown } from "./useCountDown";

function useSessionRefresh() {
  const navigate = useNavigate();
  const accessToken = fetchUserToken();
  const refreshToken = fetchRefreshToken();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const accessTokenExpiration = useMemo(() => {
    return decodeToken(accessToken || "");
  }, [accessToken]);

  const refreshTokenExpiration = useMemo(() => {
    return decodeToken(refreshToken || "");
  }, [refreshToken]);

  const { counter, start, reset } = useCountDown(accessTokenExpiration);

  useEffect(() => {
    if (counter === accessTokenExpiration) {
      start();
    }
    if (counter === 0) {
      reset(accessTokenExpiration);
    }
    //eslint-disable-next-line
  }, [counter]);

  const fetchNewToken = () => {
    setIsLoading(true);
    dispatch(refreshUserToken(false))
      .unwrap()
      .then((res) => {
        reset(decodeToken(res.token.access));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (counter !== 0) return;
    fetchNewToken();
    //eslint-disable-next-line
  }, [counter]);

  const handleUnauthorized = () => {
    if (accessToken) {
      clearUserDetails();
    }
    if (!accessToken) {
      navigate(routes.nonprotected.login, { replace: true });
    }
  };

  useLayoutEffect(() => {
    if (isLoading) return;
    if (
      (!accessToken && !document?.referrer) ||
      (accessTokenExpiration < 0 && refreshTokenExpiration < 0)
    ) {
      handleUnauthorized();
    }
    //eslint-disable-next-line
  }, [accessToken, isLoading]);

  return null;
}

export default useSessionRefresh;
