import { useLocation } from "react-router-dom";
import MultiUserId from "../../MultiUserId";
import routes from "../../../routes";
import { useAppSelector } from "../../../hooks/redux";
import { truncatedTextEnd, mergeClassName } from "../../../utils/helpers";
import WelcomeMenu from "./WelcomeMenu";
import ApplicationsMenu from "./ApplicationsMenu";
import ApplicationMenu from "./ApplicationMenu";
import { TbApiApp } from "react-icons/tb";
import LogoutButton from "../../LogoutButton";

type props = {
  className?: string;
};

// routes
const { applications } = routes.protected;

function SideNav({ className }: props) {
  const location = useLocation();
  const { user, company } = useAppSelector((state) => state.auth);
  const { email } = user;

  const renderMenu = () => {
    let template = null;
    switch (true) {
      case location.pathname.includes(applications.index):
        template = <ApplicationsMenu />;
        break;
      case location.pathname.includes(applications.app.index):
        template = <ApplicationMenu />;
        break;

      default:
        template = <WelcomeMenu />;
        break;
    }
    return template;
  };

  return (
    <nav
      className={mergeClassName(
        "grid grid-rows-[max-content_2.5rem_max-content_2.6fr_max-content_1fr_max-content] pl-12 pt-11 pb-22 ",
        className
      )}
    >
      <div className="row-start-1 row-end-2">
        <div className="flex w-max items-center gap-x-5 rounded-xl border border-gray-320/30 py-1.5 px-4 font-bold text-teal-40">
          <TbApiApp className="block h-7 w-8" />
          {truncatedTextEnd(company?.name, 16)}
        </div>
      </div>
      {renderMenu()}
      {email && <LogoutButton />}
      <MultiUserId className="row-start-6 row-end-7 hidden select-none self-end text-center md:block" />
    </nav>
  );
}

export default SideNav;
