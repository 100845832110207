import React, { useCallback } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAppSelector } from "../../../../common/hooks";
import { DynamicHorizontalNav as HorizontalNav } from "../../../../common/components/Layout/HorizontalNav";
import CustomButton from "../../../../common/components/CustomButton";
import RiskRecordQuery from "../RiskRecordQuery";
import routes from "../../../../common/routes";
import { policyNavLinks } from "../../../../common/utils/helpers/navHelpers";
// import { ArrowBack } from "../../../../static/images/icons";
import Language from "../../../../common/utils/language/en";
import banner from "../../../../static/images/banners/risks.png";
import CustomBanner from "../../../../common/components/CustomBanner";

const { policy_layout: PageDictionary } = Language.protected.Layout;

function PolicyLayout() {
  const { appid, policyid } = useParams();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const policyName = useAppSelector((state) => state.app.policy.policy_name);
  const policyName = useAppSelector(
    (state) =>
      state.app.app_policies.data.find((poliicy) => poliicy._id === policyid)
        ?.name
  );

  const clickHandler = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, link: any, name: string) => {
      e.preventDefault();
      const path = link.link(appid, policyid, params.get("policy"));
      navigate(path);
    },
    [appid, policyid, navigate] //eslint-disable-line
  );

  const isRiskRoute =
    routes.protected.applications.app.risk_control.risks.risk_record
      .link(
        !appid ? "" : appid,
        !policyid ? "" : policyid,
        params.get("policy") || ""
      )
      .includes(location.pathname);

  // const handleBack = () => {
  //   if (!appid) return;
  //   const path =
  //     routes.protected.applications.app.risk_control.policy.link(appid);
  //   navigate(path);
  // };

  const notificationConfigHandler = () => {
    if (!appid || !policyid) return;
    const path =
      routes.protected.applications.app.risk_control.risks.notification_config.add.link(
        appid,
        policyid
      );
    navigate(path, { state: { background: location } });
  };

  return (
    <section className="flex h-screen flex-col overflow-y-hidden py-8 pr-22 pl-14">
      <CustomBanner
        headline="When there is a risk? We sort it!"
        sub="View all transactions that has been classified risky."
        img={banner}
        classNames={{
          container: "bg-risk_banner text-white overflow-clip",
        }}
      />
      <header>
        {/* <button
          className="flex items-center gap-x-3 font-bold text-black"
          onClick={handleBack}
        >
          <ArrowBack scale={0.5} />
          <span>{PageDictionary.header.go_back}</span>
        </button> */}
        <h4 className="mt-2.5 text-2136 font-semibold text-gray-480">
          <span>{`${
            policyName?.toLowerCase().includes("policy")
              ? `${policyName} ${PageDictionary.header.sub_title}`
              : params.get("policy")?.replace(/_/g, " ")
          }  >     `}</span>
          <span className="text-black">{PageDictionary.header.title}</span>
        </h4>
      </header>
      <nav className="flex items-center justify-between">
        <HorizontalNav navlinks={policyNavLinks} handleClick={clickHandler} />
        {isRiskRoute ? (
          <RiskRecordQuery />
        ) : (
          <CustomButton
            className="py-3 px-4"
            onClick={notificationConfigHandler}
          >
            {PageDictionary.header.add_config}
          </CustomButton>
        )}
      </nav>

      <Outlet />
    </section>
  );
}

export default PolicyLayout;
