import React, { useId, useRef, useState } from "react";
import { BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
// import { CiPickerHalf } from "react-icons/ci";
import { CgColorPicker } from "react-icons/cg";
import CustomToolTip from "../CustomToolTip";
import Language from "../../utils/language/en";
import { mergeClassName } from "../../utils/helpers";

const { input_feild: PageDictionary } = Language.components;

interface props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  containerClassName?: string;
  labelClassName?: string;
  rules?: RegExp;
  haserror?: boolean;
  errortext?: string;
  tooltip?: string;
  iscurrency?: string;
  hasSuccess?: boolean;
  successText?: string;
}

const initialstate = {
  showPassword: false,
};

const CustomInput = React.forwardRef<HTMLInputElement, props>(
  (
    {
      label,
      type,
      name,
      className,
      containerClassName,
      rules,
      labelClassName,
      haserror,
      errortext,
      tooltip,
      iscurrency,
      hasSuccess,
      successText,
      ...props
    },
    ref
  ) => {
    const id = useId();
    const [inputState, setInputState] = useState(initialstate);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const passwordHandler = () => {
      setInputState((prev) => ({ ...prev, showPassword: !prev.showPassword }));
    };

    return (
      <div
        className={mergeClassName(
          "form_group relative flex-1",
          type === "color" ? "flex w-max flex-none items-center gap-x-2" : "",
          type === "radio"
            ? "flex flex-grow-0 flex-row-reverse items-center justify-start gap-x-3"
            : "",
          containerClassName
        )}
      >
        {label || tooltip ? (
          <label
            className={mergeClassName(
              "flex-shrink-0 text-base font-medium text-base-20",
              tooltip ? "flex items-center gap-x-1" : "inline-block",
              type === "color" || type === "radio" ? "" : "mb-2",
              type === "radio"
                ? "flex-1 flex-grow-[8] cursor-pointer text-sm leading-6"
                : "",
              labelClassName ? labelClassName : ""
            )}
            htmlFor={`${id}-${name ? name : "temp"}`}
          >
            {label}
            {tooltip && <CustomToolTip tip={tooltip} />}
          </label>
        ) : null}
        <div
          className={mergeClassName(
            "relative flex flex-1 items-center",
            type === "color" ? "w-max justify-start" : "justify-center"
          )}
        >
          <input
            {...props}
            className={mergeClassName(
              "h-14 w-full rounded-xl border bg-inputbg py-3 pl-4 text-base leading-none text-black placeholder:text-base placeholder:text-gray-320 ",
              type === "password" && "pr-12",
              haserror ? "border-red-10" : "border-blue-50",
              type === "color"
                ? "sr-only h-0 w-0 cursor-pointer p-0 opacity-0"
                : "",
              type === "radio" ? "h-auto cursor-pointer" : "",
              iscurrency && "pl-8",
              className
            )}
            name={name}
            id={`${id}-${name ? name : "temp"}`}
            type={inputState.showPassword ? "text" : type}
            data-rules={rules}
            data-haserror={haserror}
            ref={inputRef}
            data-testid="custom-input"
            readOnly={props.readOnly || type === "color" || type === "checkbox"}
          />
          {type === "password" && (
            <button
              type="button"
              className="absolute right-5 top-1/2 -translate-y-1/2 cursor-pointer text-black "
              onClick={passwordHandler}
              data-testid="password-icon"
            >
              {inputState.showPassword ? (
                <BsEyeSlashFill data-testid="show-password" />
              ) : (
                <BsEyeFill data-testid="hide-password" />
              )}
            </button>
          )}
          {type === "color" && (
            <div
            
              className="flex w-max cursor-pointer items-stretch"
              onClick={() => inputRef.current?.click()}
            >
              <span
                style={{ background: `${props.value}` }}
                className="inline-block h-8 w-8 border border-black bg-red-10"
              />
              <span className=" inline-flex h-8 w-8 items-center justify-center border-none bg-black text-white outline-none">
                <CgColorPicker />
              </span>
            </div>
          )}
          {iscurrency ? (
            <span className="absolute left-4 bottom-1/2 translate-y-1/2">
              {iscurrency}
            </span>
          ) : null}
        </div>
        {haserror && (
          <p
            data-testid="error-text"
            className="error-text-class absolute -bottom-4 right-0 mt-0.5 text-right text-xs text-red-10"
          >
            {errortext ? errortext : PageDictionary.invalid_value}
          </p>
        )}
        {hasSuccess && successText && !haserror ? (
          <p
            data-testid="success-text"
            className="error-text-class absolute -bottom-4 right-0 mt-0.5 text-right text-xs text-green-10"
          >
            {successText}
          </p>
        ) : null}
      </div>
    );
  }
);

// const InputMemo = React.memo(CustomInput);

CustomInput.displayName = "CustomInput";

export default CustomInput;
