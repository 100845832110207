import { useEffect } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEffectOnce, useAppSelector } from "../../../hooks";
import routes from "../../../routes";
import {
  fetchLocalData,
  hideGolivePrompt,
  removeGolivePrompt,
} from "../../../service/storage";
import customToast, { toast } from "../../CustomToast";
import AppLayout from "../AppLayout";

function Protected() {
  const { appid } = useParams();
  const { pathname } = useLocation();
  const { is_verified, is_2fa_enabled } = useAppSelector(
    (state) => state.auth.user
  );
  const app = useAppSelector((state) =>
    state.app.tabApps.find((app) => app._id === appid)
  );

  const { ignore_prompt_2fa, access_token, is_live_mode_ignored } =
    fetchLocalData();
  const navigate = useNavigate();
  const location = useLocation();

  useEffectOnce(() => {
    if (
      is_2fa_enabled === false &&
      ignore_prompt_2fa === null &&
      pathname.includes(routes.protected.onboarding.index) === false
    ) {
      const path = routes.protected.settings.two_factor_prompt;
      navigate(path, { state: { background: location } });
    }
  });

  const hidePromptClickHandler = () => {
    toast.dismiss("TEST-MODE");
    hideGolivePrompt();
  };

  useEffect(() => {
    if (!appid) {
      if (toast.isActive("TEST-MODE")) {
        toast.dismiss("TEST-MODE");
      }
      return;
    }
    if (app?.mode === "TEST" && is_live_mode_ignored === null) {
      const path = routes.protected.applications.app.api_settings.link(appid);
      customToast.warning(
        <p className="w-full text-xs">
          Test mode is enabled.{" "}
          <Link className="underline" to={path}>
            Visit App Setting
          </Link>{" "}
          to switch to Live
        </p>,
        {
          closeButton: (
            <button onClick={hidePromptClickHandler}>dismiss</button>
          ),
          toastId: "TEST-MODE",
          position: "bottom-center",
          autoClose: false,
          closeOnClick: false,
        }
      );
    }
    return () => {
      if (app?.mode === "TEST" && is_live_mode_ignored !== null) {
        removeGolivePrompt();
      }
    };
  }, [appid, pathname, app?.mode, is_live_mode_ignored]);

  if (!access_token || is_verified === false) {
    return <Navigate replace to={routes.nonprotected.login} />;
  }

  return <AppLayout />;
}

export default Protected;
