import { ENDPOINTS } from "../../../common/service/config/endpoint";
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  queryHandler,
} from "../../../common/service/request";
import { IexchangeRate } from "../../wallet/types";
import * as tp from "../types";

const service = {
  async getUtility(id: string): Promise<tp.APIResponseModel<tp.Iwallet>> {
    const { method, url: makeUrl } = ENDPOINTS.utility.get_wallet;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getExchangeRate(
    id: string
  ): Promise<tp.APIResponseModel<IexchangeRate>> {
    const { method, url: makeUrl } = ENDPOINTS.wallet.get_exchange_rate;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async fundUtilityWallet(
    id: string,
    data: tp.IWalletTrans
  ): Promise<tp.APIResponseModel<null>> {
    const { method, url: makeUrl } = ENDPOINTS.utility.fund_wallet;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async withdrawUtilityWallet(
    id: string,
    data: tp.IWalletTrans
  ): Promise<tp.APIResponseModel<null>> {
    const { method, url: makeUrl } = ENDPOINTS.utility.withdraw_funds;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getWithdrawalThreshold(
    id: string
  ): Promise<tp.APIResponseModel<tp.IthresholdData>> {
    const { method, url: makeUrl } = ENDPOINTS.utility.get_withdrawal_threshold;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async setWithdrawalThreshold(
    id: string,
    data: tp.IsetThreshold
  ): Promise<tp.APIResponseModel<tp.IthresholdData>> {
    const { method, url: makeUrl } = ENDPOINTS.utility.set_withdrawal_threshold;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async removeWithdrawalThreshold(
    id: string
  ): Promise<tp.APIResponseModel<null>> {
    const { method, url: makeUrl } =
      ENDPOINTS.utility.remove_withdrawal_threshold;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async listUtilityCategory(
    id: string
  ): Promise<tp.APIResponseModel<tp.IutilityCategory[]>> {
    const { method, url: makeUrl } = ENDPOINTS.utility.list_utility_category;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async listSingleUtilityCategory(
    id: string,
    utilid: string
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } =
      ENDPOINTS.utility.list_single_utility_category;
    const url = makeUrl(id, utilid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getUtilityRecords(
    req: tp.IlistTransaction
  ): Promise<tp.APIResponseModel<tp.IutilityItemRecord[]>> {
    const { method, url: makeUrl } = ENDPOINTS.utility.utility_wallet_record;
    const { appid, ...otherQuery } = req;
    const query = queryHandler({ ...otherQuery, category: "utility" });
    const url = makeUrl(appid, query);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getUtilityHistory(
    req: tp.IlistTransaction
  ): Promise<tp.APIResponseModel<tp.IutilityItemHistory[]>> {
    const { method, url: makeUrl } = ENDPOINTS.utility.utility_history;
    // const { appid, page, size, cat, country, sub_cat } = req;
    // const url = makeUrl(appid, page, size, country, cat, sub_cat);
    const { appid, ...otherQuery } = req;
    const query = queryHandler({ ...otherQuery, category: "utility" });
    const url = makeUrl(appid, query);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
