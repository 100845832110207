import template1_1 from "../../../../../static/images/include/template1/1.png";
import template1_2 from "../../../../../static/images/include/template1/2.png";
import template1_3 from "../../../../../static/images/include/template1/3.png";
import template1_4 from "../../../../../static/images/include/template1/4.png";
import template1_5 from "../../../../../static/images/include/template1/5.png";
import template1_6 from "../../../../../static/images/include/template1/6.png";
import template1_7 from "../../../../../static/images/include/template1/7.png";
import template1_8 from "../../../../../static/images/include/template1/8.png";
import template1_9 from "../../../../../static/images/include/template1/9.png";
import template1_10 from "../../../../../static/images/include/template1/10.png";
import template1_11 from "../../../../../static/images/include/template1/11.png";
import template1_12 from "../../../../../static/images/include/template1/12.png";

import template2_1 from "../../../../../static/images/include/template2/1.png";
import template2_2 from "../../../../../static/images/include/template2/2.png";
import template2_3 from "../../../../../static/images/include/template2/3.png";
import template2_4 from "../../../../../static/images/include/template2/4.png";
import template2_5 from "../../../../../static/images/include/template2/5.png";
import template2_6 from "../../../../../static/images/include/template2/6.png";
import template2_7 from "../../../../../static/images/include/template2/7.png";
import template2_8 from "../../../../../static/images/include/template2/8.png";
import template2_9 from "../../../../../static/images/include/template2/9.png";
import template2_10 from "../../../../../static/images/include/template2/10.png";
import template2_11 from "../../../../../static/images/include/template2/11.png";
import template2_12 from "../../../../../static/images/include/template2/12.png";

import template3_1 from "../../../../../static/images/include/template3/1.png";
import template3_2 from "../../../../../static/images/include/template3/2.png";
import template3_3 from "../../../../../static/images/include/template3/3.png";
import template3_4 from "../../../../../static/images/include/template3/4.png";
import template3_5 from "../../../../../static/images/include/template3/5.png";
import template3_6 from "../../../../../static/images/include/template3/6.png";

import template4_1 from "../../../../../static/images/include/template4/1.png";
import template4_2 from "../../../../../static/images/include/template4/2.png";
import template4_3 from "../../../../../static/images/include/template4/3.png";
import template4_4 from "../../../../../static/images/include/template4/4.png";
import template4_5 from "../../../../../static/images/include/template4/5.png";
import template4_6 from "../../../../../static/images/include/template4/6.png";
import template4_7 from "../../../../../static/images/include/template4/7.png";

export const slides = {
  template_1: [
    template1_1,
    template1_2,
    template1_3,
    template1_4,
    template1_5,
    template1_6,
    template1_7,
    template1_8,
    template1_9,
    template1_10,
    template1_11,
    template1_12,
  ],
  template_2: [
    template2_1,
    template2_2,
    template2_3,
    template2_4,
    template2_5,
    template2_6,
    template2_7,
    template2_8,
    template2_9,
    template2_10,
    template2_11,
    template2_12,
  ],
  template_3: [
    template3_1,
    template3_2,
    template3_3,
    template3_4,
    template3_5,
    template3_6,
  ],
  template_4: [
    template4_1,
    template4_2,
    template4_3,
    template4_4,
    template4_5,
    template4_6,
    template4_7,
  ],
};
