import React from "react";
// import image from "../../../../../../static/images/banners/plugin-branding.png";
import useNavigateModal from "../../../../../../common/hooks/useNavigateModal";
import routes from "../../../../../../common/routes";
import { useParams } from "react-router-dom";

type Props = {
  title: string;
  sub: string;
  img: string;
  type: string;
};

function Card({ sub, title, img, type }: Props) {
  const navigate = useNavigateModal();
  const appid = useParams()?.appid as string;

  const viewTemplateHandler = () => {
    if (!appid || !title) return;
    const path =
      routes.protected.applications.app.include.template.view_template.link(
        appid,
        type
      );
    navigate(path);
  };

  return (
    <div className="flex flex-col gap-y-4.5">
      <div className="flex justify-end overflow-clip rounded-2xl">
        <img src={img} alt="banner" />
      </div>
      <div className="flex flex-col gap-y-2.5">
        <h4 className="mb-1 text-3236 font-extrabold leading-7 text-[#372A59]">
          {title}
        </h4>
        <p className="text-xs font-medium text-[#392943]">{sub}</p>
        <button
          className="self-start rounded-md bg-[#D0D6F4] py-1.5 px-3.5 text-xs text-[#0D0D0D]"
          onClick={viewTemplateHandler}
        >
          View Template
        </button>
      </div>
    </div>
  );
}

export default Card;
