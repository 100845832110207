import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { IsettingsSlice } from "../types/module";
import { getActivityLog, updatePassword, updateUserProfile } from "./thunk";
import { logUserOut } from "../../auth/store/thunk";

const initialState: IsettingsSlice = {
  loading: false,
  error: null,
  activity_log: {
    data: [],
    loading: false,
    totalCount: 0,
  },
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(logUserOut.fulfilled, (state) => {
        state.activity_log = { ...initialState.activity_log };
      })
      .addCase(getActivityLog.fulfilled, (state, { payload }) => {
        state.activity_log.data = payload.data;
        state.activity_log.totalCount = payload.results;
      })
      .addMatcher(isAnyOf(getActivityLog.pending), (state) => {
        state.activity_log.loading = true;
      })
      .addMatcher(
        isAnyOf(getActivityLog.rejected, getActivityLog.fulfilled),
        (state) => {
          state.activity_log.loading = initialState.activity_log.loading;
        }
      )
      .addMatcher(
        isAnyOf(updatePassword.pending, updateUserProfile.pending),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          updatePassword.fulfilled,
          updatePassword.rejected,
          updateUserProfile.fulfilled,
          updateUserProfile.rejected
        ),
        (state) => {
          state.loading = initialState.loading;
        }
      );
  },
});

// export const {} = settingSlice.actions;

export default settingSlice.reducer;
