import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Banner from "../Banner";
import CustomPageTitle from "../../../../common/components/CustomPageTitle";
import Language from "../../../../common/utils/language/en";

const { header: PageDictionary } = Language.protected.Layout.utility_layout;

function Layout() {
  const { pathname } = useLocation();

  return (
    <section className="flex h-full flex-col">
      <p className="mb-5 text-xs font-medium text-teal-40">
        {PageDictionary.headline_text}
      </p>
      <Banner />
      <section className="flex flex-1 flex-col gap-y-6">
        <CustomPageTitle
          title={`Utility ${
            pathname.includes("history") ? "History" : "Records"
          }`}
          small
          classNames={{
            title: "text-2226 font-bold",
            container: "-mb-5 flex items-center justify-between pt-15",
          }}
          tooltip={
            pathname.includes("history")
              ? "This is the records of utilities purchased by your user"
              : "The utilities wallet is your prefunded wallet used to process your endusers purchases of utilies"
          }
        />
        <Outlet />
      </section>
    </section>
  );
}

export default Layout;
