import { z } from "zod";

export const validationRules = {
  string: /(.|\s)*\S(.|\s)*/,
  email:
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
  phone: /^\+?[1-9][0-9]{7,14}$/,
  number: /^\d+$/,
  valid_number: /^(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?$/,
  website:
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/, //eslint-disable-line
  domain:
    /^http:\/\/localhost|https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{1,6}(\/.*)?$/, //eslint-disable-line
};

export const amountValidation = (label?: string) => {
  return z
    .string({ message: `${label ?? "Amount"} is required` })
    .refine(
      (value) => {
        const validFormat = validationRules.valid_number.test(value);
        if (!validFormat) return false;
        const cleanedValue = parseFloat(value.replace(/,/g, ""));
        return !isNaN(cleanedValue) && cleanedValue > 0;
      },
      {
        message: `${label ?? "Amount"} must be a valid positive number`,
      }
    )
    .transform((value) => {
      return value.replace(/,/g, "");
    });
};

export const fileValidator = (message: string) => {
  return z
    .any()
    .refine((file: any) => file instanceof File, {
      message,
    })
    .refine((file: File) => file != null && file.size <= 2 * 1024 * 1024, {
      message: "Max file size is 2MB",
    })
    .refine(
      (file: File) =>
        file != null &&
        ["image/jpeg", "image/png", "application/pdf"].includes(file.type),
      {
        message: "Only .pdf, .jpg, and .png files are allowed",
      }
    );
};

export const imageValidator = (message: string) => {
  return z
    .any()
    .refine((file: any) => file instanceof File, {
      message,
    })
    .refine((file: File) => file != null && file.size <= 2 * 1024 * 1024, {
      message: "Max file size is 2MB",
    })
    .refine(
      (file: File) =>
        file != null && ["image/jpeg", "image/png"].includes(file.type),
      {
        message: "Only .jpg, and .png files are allowed",
      }
    );
};

export const urlValidator = (message?: Record<string, string>) => {
  return z
    .string({ message: message?.string || "Invalid URL" })
    .refine((url) => validationRules.domain.test(url), {
      message: message?.refine || "Invalid URL format",
    });
};

const VALIDATION_RULES = [
  {
    id: 1,
    title: "1 Capital Letter",
    passed: false,
    regex: "^(?=.*?[A-Z])",
  },
  { id: 2, title: "1 Number", passed: false, regex: "(?=.*?[0-9])" },
  {
    id: 3,
    title: "1 Special Character from !@#$%^&_",
    passed: false,
    regex: "(?=.*[!@#$%^&_])",
  },
  { id: 4, title: "8 Characters", passed: false, regex: ".{8,}" },
];

export const CheckPasswordStrength = (
  password: string
): { count: number; total: number } => {
  if (!password) return { count: 0, total: VALIDATION_RULES.length };

  let count = 0;

  VALIDATION_RULES.forEach((rule) => {
    if (password.match(rule.regex)) count += 1;
  });

  return { count, total: VALIDATION_RULES.length };
};

type WithdrawalHelperType = {
  balance?: number;
  amount: string;
  serviceCharge?: number; //amount finswich charge for withdrawal (in USD)
  shareholderId: string;
};

export const withdrawalHelper = ({
  amount,
  balance = 0,
  serviceCharge = 1,
  shareholderId,
}: WithdrawalHelperType) => {
  const hasSufficientBalance = balance - serviceCharge > 0;
  const canMakeWithdrawal =
    balance > parseInt(amount || "0") && shareholderId !== "";
  return {
    hasSufficientBalance,
    canMakeWithdrawal,
  };
};
