import React, { ReactNode, useId, useRef } from "react";
import { mergeClassName } from "../../utils/helpers";

type Props = {
  label: string | ReactNode;
  className?: string;
  lclassName?: string;
  cclassName?: string;
  check?: boolean;
  name?: string;
  onToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  isLoading?: boolean;
};

function CustomToggle({
  label,
  className,
  lclassName,
  cclassName,
  check,
  onToggle,
  name,
  disabled,
  isLoading,
}: Props) {
  const id = useId();
  const ref = useRef<any>(null);
  const clickHandler = () => ref.current.click();

  return (
    <div
      className={mergeClassName(
        "grid-col-[max-content_max-content] grid ",
        className
      )}
    >
      <label
        htmlFor={`${id}-toogle`}
        className={mergeClassName(
          "relative inline-block cursor-pointer",
          cclassName
        )}
      >
        <input
          ref={ref}
          type="checkbox"
          id={`${id}-toogle`}
          className="sr-only"
          checked={check}
          name={name}
          onChange={onToggle}
          disabled={disabled}
        />
        <div
          className={mergeClassName(
            "toggle-bg !m-0 h-6 w-11 rounded-full border-2 border-gray-380 bg-gray-380",
            isLoading ? "animate-pulse" : ""
          )}
        />
      </label>
      {label ? (
        <span
          className={mergeClassName(
            "text-950 text-gray-150 col-start-2 col-end-3 row-start-1 row-end-3 justify-self-start text-left",
            disabled ? "cursor-not-allowed" : "cursor-pointer",
            lclassName
          )}
          onClick={clickHandler}
        >
          {label}
        </span>
      ) : null}
    </div>
  );
}

export default CustomToggle;
