import React from "react";
import CustomPageTitle from "../../../../../common/components/CustomPageTitle";
import Card from "./components/Card";
import template1 from "../../../../../static/images/include/template1/base.png";
import template2 from "../../../../../static/images/include/template2/base.png";
import template3 from "../../../../../static/images/include/template3/base.png";
import template4 from "../../../../../static/images/include/template4/base.png";

export const cards = [
  {
    title: "Luna",
    sub: "Pop, minimalistic and vibrant, for your delight and performance.",
    img: template1,
    type: "template_1",
  },
  {
    title: "Nemo",
    sub: "Simplicity meeting sophistication, designed to keep you focused.",
    img: template2,
    type: "template_2",
  },
  {
    title: "Polly",
    sub: "Brighten your ideas with a touch of simplicity and style",
    img: template3,
    type: "template_3",
  },
  {
    title: "Luz",
    sub: "Sleek design rooted in elegance, build for lasting impressions",
    img: template4,
    type: "template_4",
  },
];

function Templates() {
  return (
    <div className="flex h-full flex-col gap-y-6 pb-28">
      <CustomPageTitle
        title="App Templates"
        subTitle="Explore available app templates you want to use for your own fintech app."
        tooltip="temp"
        classNames={{
          texts: "gap-y-2 flex flex-col",
        }}
      />
      <div className="grid grid-cols-[repeat(auto-fit,_minmax(15.2rem,_1fr))] gap-7">
        {cards.map((card, index) => (
          <Card
            key={index}
            sub={card.sub}
            title={card.title}
            img={card.img}
            type={card.type}
          />
        ))}
      </div>
    </div>
  );
}

export default Templates;
