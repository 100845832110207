import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { mergeClassName } from "../utils/helpers";

type Props = {
  setting?: Settings;
  slides: React.ReactNode[];
  className?: string;
};

const NextArrow = (props: { onClick?: () => void }) => (
  <button
    onClick={props.onClick}
    className="absolute -bottom-16 right-4 z-10 -translate-y-1/2 transform rounded-full p-2 text-[#392943]"
  >
    Next &rarr;
  </button>
);

const PrevArrow = (props: { onClick?: () => void }) => (
  <button
    onClick={props.onClick}
    className="absolute -bottom-16 left-4 z-10 -translate-y-1/2 transform rounded-full p-2 text-[#392943]"
  >
    &larr; Previous
  </button>
);

function CustomCarousel({ setting, slides, className }: Props) {
  const defaultSettings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    ...setting,
  };

  return (
    <div className={mergeClassName(className, "relative")}>
      <Slider {...defaultSettings}>
        {slides.map((slide, index) => (
          <div key={index} className="p-4">
            {slide}
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CustomCarousel;
