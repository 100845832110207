import { AiOutlineSetting } from "react-icons/ai";
import { MdOutlinePreview, MdOutlineSpaceDashboard } from "react-icons/md";
import { BsBank, BsBriefcase, BsFileEarmarkCodeFill } from "react-icons/bs";
import { RiApps2Fill, RiShieldUserLine } from "react-icons/ri";
import routes from "../../routes";
import Language from "../language/en";
import { BiReceipt, BiWallet } from "react-icons/bi";
import { TbSquaresDiagonal } from "react-icons/tb";
import { GiSwipeCard } from "react-icons/gi";
import { RiSettings5Fill } from "react-icons/ri";
import { HiUsers } from "react-icons/hi";
import { FaDiceFive, FaTasks } from "react-icons/fa";

type Inavcomp = {
  title: string;
  link: string | any;
  icon?: any;
  name: string;
  id?: string;
  primary?: string;
  child?: Inavcomp[];
};

const {
  sidenav: { nav: sidenav, in_app: inappnav },
  // application_layout: { nav: applicationnav },
  policy_layout: { nav: policynav },
  wallet_setting_layout: { nav: walletnav },
  compliance_layout: { nav: compliancenav },
  onboarding_layout: { nav: onboardnav },
  setting_layout: { nav: settingnav },
  // utility_layout: { nav: utilitynav },
  // include_layout: { nav: includenav },
} = Language.protected.Layout;

export const menuNavLinks: Inavcomp[] = [
  {
    title: sidenav.welcome,
    icon: <MdOutlineSpaceDashboard className="h-5 w-5" />,
    link: routes.protected.welcome,
    name: "welcome",
  },
  {
    title: sidenav.applications,
    icon: <RiApps2Fill className="h-5 w-5 rotate-45" />,
    link: routes.protected.applications.list,
    name: "applications",
  },
];

export const homeNavLinks: Inavcomp[] = [
  {
    title: sidenav.welcome,
    icon: <MdOutlineSpaceDashboard className="h-5 w-5" />,
    link: routes.protected.welcome,
    name: "welcome",
  },
  {
    title: sidenav.settings,
    icon: <AiOutlineSetting className="h-5 w-5" />,
    link: routes.protected.settings.index,
    name: "settings",
  },
  {
    title: sidenav.user,
    icon: <RiShieldUserLine className="h-5 w-5" />,
    link: routes.protected.user.index,
    name: "user",
  },
  {
    title: sidenav.compliance,
    icon: <BsBriefcase className="h-5 w-5" />,
    link: routes.protected.compliance.index,
    name: "compliance",
  },
];
export const appNavLinks: Inavcomp[] = [
  {
    title: sidenav.apps,
    icon: <FaDiceFive className="h-5 w-5" />,
    link: routes.protected.applications.list,
    name: "all",
  },
  // {
  //   title: sidenav.white_label.index,
  //   icon: <HiMiniCodeBracketSquare className="h-5 w-5" />,
  //   link: routes.protected.white_label.plans,
  //   name: "white-label",
  //   child: [
  //     {
  //       link: routes.protected.white_label.plans,
  //       name: "plans",
  //       title: sidenav.white_label.plans,
  //     },
  //     {
  //       link: routes.protected.white_label.active_subscription,
  //       name: "active-subscription",
  //       title: sidenav.white_label.active_subscriptions,
  //     },
  //     {
  //       link: routes.protected.white_label.merchants,
  //       name: "merchants",
  //       title: sidenav.white_label.merchant,
  //     },
  //   ],
  // },
];
export const inAppNavLinks: Inavcomp[] = [
  {
    title: inappnav.wallet.index,
    icon: <BiWallet className="h-5 w-5" />,
    link: routes.protected.applications.app.wallet,
    name: "wallet/",
  },
  {
    title: inappnav.transactions.index,
    icon: <BiReceipt className="h-5 w-5" />,
    link: routes.protected.applications.app.transactions.incomingTx,
    name: "transactions/",
    child: [
      {
        link: routes.protected.applications.app.transactions.incomingTx,
        name: "incoming-settlements",
        title: inappnav.transactions.incoming,
      },
      {
        link: routes.protected.applications.app.transactions.outgoingTx,
        name: "outgoing-transactions",
        title: inappnav.transactions.outgoing,
      },
    ],
  },
  {
    title: inappnav.vas_services.index,
    icon: <TbSquaresDiagonal className="h-5 w-5 rotate-45" />,
    link: routes.protected.applications.app.vas_services.utility.records,
    name: "vas-services",
    child: [
      {
        link: routes.protected.applications.app.vas_services.utility.records,
        name: "records",
        title: inappnav.vas_services.records,
      },
      {
        link: routes.protected.applications.app.vas_services.utility.history,
        name: "history",
        title: inappnav.vas_services.utilities,
      },
    ],
  },
  {
    title: inappnav.virtual_cards.index,
    icon: <GiSwipeCard className="h-5 w-5 rotate-45" />,
    link: routes.protected.applications.app.virtual_cards.wallet_record,
    name: "virtual-cards",
    child: [
      {
        link: routes.protected.applications.app.virtual_cards.wallet_record,
        name: "cards/wallet",
        title: inappnav.virtual_cards.wallet,
      },
      {
        link: routes.protected.applications.app.virtual_cards.cards_record,
        name: "cards/cards",
        title: inappnav.virtual_cards.cards,
      },
      {
        link: routes.protected.applications.app.virtual_cards
          .transactions_record,
        name: "cards/transactions",
        title: inappnav.virtual_cards.transactions,
      },
      {
        link: routes.protected.applications.app.virtual_cards.pricing,
        name: "cards/pricing",
        title: inappnav.virtual_cards.pricing,
      },
    ],
  },
  {
    title: inappnav.neo_banking.index,
    icon: <BsBank className="h-5 w-5" />,
    link: routes.protected.applications.app.include.pricing,
    name: "include",
    child: [
      // {
      //   link: routes.protected.applications.app.include.wallet,
      //   name: "money",
      //   title: inappnav.neo_banking.wallet,
      // },
      {
        link: routes.protected.applications.app.include.pricing,
        name: "pricing",
        title: inappnav.neo_banking.pricing,
      },
      {
        link: routes.protected.applications.app.include.url,
        name: "url",
        title: inappnav.neo_banking.domain,
      },
      {
        link: routes.protected.applications.app.include.config,
        name: "include/config",
        title: inappnav.neo_banking.branding,
      },
      {
        link: routes.protected.applications.app.include.template,
        name: "template",
        title: inappnav.neo_banking.template,
      },
    ],
  },
  {
    title: inappnav.plugin_services.index,
    icon: <BsFileEarmarkCodeFill className="h-5 w-5" />,
    link: routes.protected.applications.app.checkout_settings.services,
    name: "checkout-settings",
    child: [
      {
        link: routes.protected.applications.app.checkout_settings.services,
        name: "services",
        title: inappnav.plugin_services.services,
      },
      {
        link: routes.protected.applications.app.checkout_settings.branding,
        name: "branding",
        title: inappnav.plugin_services.branding,
      },
    ],
  },
  {
    title: inappnav.users.index,
    icon: <HiUsers className="h-5 w-5" />,
    link: routes.protected.applications.app.users,
    name: "users",
  },
  {
    title: inappnav.risk_control.index,
    icon: <FaTasks className="h-5 w-5" />,
    link: routes.protected.applications.app.risk_control.policy,
    name: "risk-control",
    child: [
      {
        link: routes.protected.applications.app.risk_control.policy,
        name: "policy",
        title: inappnav.risk_control.policy,
      },
      {
        link: routes.protected.applications.app.risk_control.categories,
        name: "category",
        title: inappnav.risk_control.categories,
      },
      {
        link: routes.protected.applications.app.risk_control.risks,
        name: "config",
        title: inappnav.risk_control.risks,
      },
    ],
  },
  {
    title: inappnav.settings.index,
    icon: <RiSettings5Fill className="h-5 w-5" />,
    link: routes.protected.applications.app.api_settings.api_keys,
    name: "api-settings",
    child: [
      {
        link: routes.protected.applications.app.api_settings.api_keys,
        name: "api-keys",
        title: inappnav.settings.api_keys,
      },
      {
        link: routes.protected.applications.app.api_settings.prod_webhooks,
        name: "prod-webhooks",
        title: inappnav.settings.prod_webhooks,
      },
      {
        link: routes.protected.applications.app.api_settings.test_webhooks,
        name: "test-webhooks",
        title: inappnav.settings.test_webhooks,
      },
    ],
  },
];

export const navComponents: Inavcomp[] = [
  {
    title: sidenav.dashboard,
    icon: <MdOutlineSpaceDashboard className="h-5 w-5" />,
    link: routes.protected.dashboard,
    name: "dashboard",
  },
  {
    title: sidenav.applications,
    icon: <RiApps2Fill className="h-5 w-5 rotate-45" />,
    link: routes.protected.applications.index,
    name: "applications",
  },
  {
    title: sidenav.compliance,
    icon: <BsBriefcase className="h-5 w-5" />,
    link: routes.protected.compliance.index,
    name: "compliance",
  },
  {
    title: sidenav.settings,
    icon: <AiOutlineSetting className="h-5 w-5" />,
    link: routes.protected.settings.index,
    name: "settings",
  },
  {
    title: sidenav.user,
    icon: <RiShieldUserLine className="h-5 w-5" />,
    link: routes.protected.user.index,
    name: "user",
  },
  {
    title: sidenav.onboarding,
    icon: <AiOutlineSetting className="h-5 w-5" />,
    link: routes.protected.onboarding.index,
    name: "onboarding",
  },
  {
    title: sidenav.preview,
    icon: <MdOutlinePreview className="h-5 w-5" />,
    link: routes.protected.preview_app,
    name: "onboarding",
  },
];

// export const applicationNavLinks = [
//   {
//     title: applicationnav.wallet,
//     link: routes.protected.applications.app.wallet,
//     name: "wallet",
//   },
//   {
//     title: applicationnav.outgoing_tx,
//     link: routes.protected.applications.outgoingTx,
//     name: "outgoing",
//   },
//   {
//     title: applicationnav.incoming_tx,
//     link: routes.protected.applications.incomingTx,
//     name: "incoming",
//   },
//   {
//     title: applicationnav.utility,
//     link: routes.protected.applications.app.vas_services.utility.history,
//     name: "utility",
//   },
//   {
//     title: applicationnav.users,
//     link: routes.protected.applications.users,
//     name: "users",
//   },
//   {
//     title: applicationnav.policy,
//     link: routes.protected.applications.policy,
//     name: "policy",
//   },
//   {
//     title: applicationnav.api_setting,
//     link: routes.protected.applications.api_settings,
//     name: "api",
//   },
//   {
//     title: applicationnav.preferences,
//     link: routes.protected.applications.app.checkout_settings,
//     name: "checkout",
//   },
//   // {
//   //   title: applicationnav.analytics,
//   //   link: routes.protected.applications.analytics,
//   //   name: "analytics",
//   // },
//   {
//     title: applicationnav.include,
//     link: routes.protected.applications.include.wallet,
//     name: "include",
//   },
// ];
export const policyNavLinks = [
  {
    title: policynav.risk_record,
    link: routes.protected.applications.app.risk_control.risks.risk_record,
    name: "record",
  },
  {
    title: policynav.noti_config,
    link: routes.protected.applications.app.risk_control.risks
      .notification_config,
    name: "notification",
  },
];
export const withdrawalSettingNavLinks = [
  {
    title: walletnav.crypto,
    link: routes.protected.applications.app.wallet.settings.withdrawal_details
      .crypto,
    name: "crypto",
  },
  {
    title: walletnav.bank,
    link: routes.protected.applications.app.wallet.settings.withdrawal_details
      .bank,
    name: "bank",
  },
];
// export const utilityNavLinks = [
//   {
//     title: utilitynav.records,
//     link: routes.protected.applications.app.vas_services.utility.records,
//     name: "records",
//   },
//   {
//     title: utilitynav.history,
//     link: routes.protected.applications.app.vas_services.utility.history,
//     name: "history",
//   },
// ];
export const complianceNavLinks = [
  {
    name: compliancenav.bussiness_document,
    link: routes.protected.compliance.business_document.index,
  },
  {
    name: compliancenav.bussiness_shareholders,
    link: routes.protected.compliance.shareholders.index,
  },
  {
    name: compliancenav.bussiness_profile,
    link: routes.protected.compliance.bussiness_profile,
  },
];
export const onboardNavLinks = [
  {
    name: onboardnav.terms,
    link: routes.protected.onboarding.terms.index,
  },
  {
    name: onboardnav.bussiness_shareholders,
    link: routes.protected.onboarding.shareholders.index,
  },
  {
    name: onboardnav.bussiness_profile,
    link: routes.protected.onboarding.bussiness_profile,
  },
  {
    name: onboardnav.bussiness_document,
    link: routes.protected.onboarding.business_document.index,
  },
];
export const settingNavLinks = [
  {
    name: settingnav.security,
    link: routes.protected.settings.security,
  },
  // {
  //   name: settingnav.business_profile,
  //   link: routes.protected.settings.business_profile,
  // },
  {
    name: settingnav.tx_country,
    link: routes.protected.settings.transaction_country,
  },
  // {
  //   name: settingnav.activity_log,
  //   link: routes.protected.settings.activity_log,
  // },
];

// export const includeNavLinks = [
//   {
//     name: includenav.wallet,
//     link: routes.protected.applications.include.wallet,
//   },
//   {
//     name: includenav.url,
//     link: routes.protected.applications.include.url,
//   },
//   {
//     name: includenav.pricing,
//     link: routes.protected.applications.include.pricing,
//   },
//   {
//     name: includenav.config,
//     link: routes.protected.applications.include.config,
//   },
// ];
