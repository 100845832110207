import { useEffect, useRef } from "react";
import { Outlet, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  usePageTitle,
} from "../../../../common/hooks";
import Language from "../../../../common/utils/language/en";
import useHistroy from "../../../../common/hooks/useHistory";
import { appStateActions } from "../../../../store/helperReducers/appStateSlice";

const { page_title: PageDictionary } =
  Language.protected.Layout.application_layout;

type Props = {
  children?: React.ReactNode;
};

function Layout({ children }: Props) {
  const { appid } = useParams();

  const app = useAppSelector((state) =>
    state.app.tabApps.find((app) => app._id === appid)
  );
  const dispatch = useAppDispatch();

  const { routeHistory } = useHistroy();

  useEffect(() => {
    if (routeHistory.length === 0 || !appid) return;
    const lastMatchingValue = routeHistory
      .filter((path) => path.includes(appid)) // Filter for paths containing the substring
      .pop(); // Get the last matching path

    if (!lastMatchingValue) return;

    dispatch(
      appStateActions.updateTabLink({ id: appid, link: lastMatchingValue })
    );
  }, [routeHistory.length]); //eslint-disable-line

  const layoutRef = useRef<HTMLElement | null>(null);

  usePageTitle(PageDictionary(app?.name));

  return (
    <section className="flex h-full flex-col overflow-scroll py-8 px-8 md:px-16">
      <div className="flex flex-1 flex-col">
        {children ? children : <Outlet context={layoutRef} />}
      </div>
    </section>
  );
}

export default Layout;
