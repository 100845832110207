import { useAppSelector } from "@/common/hooks";
import { mergeClassName } from "@/common/utils/helpers";
import { useParams } from "react-router-dom";

function IncludeBadge() {
  const { appid } = useParams();
  const appdata = useAppSelector((state) =>
    state.app.tabApps.find((app) => app._id === appid)
  );

  const isIncludeTurnedOn = appdata?.meta_data?.include_status === "ON";
  return (
    <span
      className={mergeClassName(
        "block rounded-lg py-1 px-1 text-917 leading-3 text-black font-normal",
        isIncludeTurnedOn ? "bg-green-300" : "bg-red-300"
      )}
    >
      {isIncludeTurnedOn ? "ON" : "OFF"}
    </span>
  );
}

export default IncludeBadge;
