import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../../../common/components/CustomButton";
import CustomInput from "../../../../../common/components/CustomInput";
import CenterModal from "../../../../../common/components/CustomModal/CenterModal";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../../common/hooks";
import { includeSwitchOff } from "../../../store/thunk";
import { Iapp, iHandleChange, iHandleSubmit } from "../../../types";
import Language from "../../../../../common/utils/language/en";

const { turnOffInclude: PageDictionary } = Language.protected.IncludeModals;

const initialData = {
  password: "",
  app_id: "",
  id: "",
};

export function TurnOffInclude() {
  const appid = useParams()?.appid as string;
  const { state } = useLocation();
  const bgPathname = state?.background.pathname as string;
  const { loading } = useAppSelector((state) => state.app.allApps);
  const allApps = useAppSelector((state) => state.app.tabApps);
  const app = allApps.find((app) => app._id === appid) as Iapp;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [inData, setInData] = useState(initialData);

  const changeHandler: iHandleChange = useCallback((e) => {
    const { value } = e.target;
    setInData((prev) => ({ ...prev, password: value }));
  }, []);

  useEffectOnce(() => {
    if (!appid) return;
    setInData((prev) => ({ ...prev, app_id: app.app_id, id: appid }));
    // setInData((prev) => ({ ...prev, app_id: app._id, id: app.app_id }));
  });

  const cancelHandler = () => {
    if (loading) return;
    navigate(bgPathname, { replace: true });
  };

  const onSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    dispatch(includeSwitchOff(inData))
      .unwrap()
      .then(cancelHandler)
      .catch(cancelHandler);
  };

  return (
    <CenterModal handleClose={cancelHandler} blurBg>
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
        autoComplete="off"
      >
        <h3 className="text-2xl text-red-10">{PageDictionary.header.title}</h3>
        <p>
          {PageDictionary.header.sub.text}
          <br />
          <span className="text-red-10">{PageDictionary.header.sub.cta}</span>
        </p>

        <CustomInput
          label={PageDictionary.form.passwordLabel}
          containerClassName="self-stretch flex flex-col"
          labelClassName="text-left"
          type="password"
          autoComplete="new-password"
          name="password"
          value={inData.password}
          onChange={changeHandler}
        />
        <div className="flex items-center gap-x-9 self-end">
          <CustomButton type="button" variant onClick={cancelHandler}>
            {PageDictionary.form.cancelButton}
          </CustomButton>
          <CustomButton
            isloading={loading}
            type="submit"
            disabled={inData.password === ""}
          >
            {PageDictionary.form.submitButton}
          </CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}
