import { useAppSelector } from "../../../../common/hooks";
import CustomInput from "../../../../common/components/CustomInput";
import { iHandleChange } from "../../types";
import { Link } from "react-router-dom";
import routes from "../../../../common/routes";

type Props = {
  radioChangeHandler: iHandleChange;
  value: string;
  disabled?: boolean;
};

function ShareholdersWithKyc({ radioChangeHandler, value, disabled }: Props) {
  const { data: shareholders } = useAppSelector(
    (state) => state.compliance.shareholders
  );
  return (
    <ul className="list-inside list-decimal">
      <li className="space-y-2.5 text-inherit">
        <span className="text-sm">
          Transfer money from the account of “Allowed users” with active KYC
          below.{" "}
          <span className="block italic text-red-10">
            Third Party Funding is not allowed.
          </span>
        </span>
        <div className="flex flex-col gap-y-5 rounded border border-dashed border-[#384EB74D] bg-[#F8F8FF] py-3 px-4 ">
          <table className="w-full text-left">
            <thead className="text-sm text-gray-410">
              <tr>
                <th>Account Name</th>
                <th>Status</th>
                <th className="text-center">Select</th>
              </tr>
            </thead>
            <tbody className="text-base text-base-20">
              {shareholders.map((shareholder) => (
                <tr key={shareholder._id}>
                  <td className="capitalize">{shareholder.name}</td>
                  <td>
                    <div className="w-fit rounded bg-[#12C089] py-1.5 px-4 text-white">
                      Active KYC
                    </div>
                  </td>
                  <td>
                    <CustomInput
                      type="radio"
                      name="shareholder_id"
                      value={shareholder._id}
                      onChange={radioChangeHandler}
                      disabled={disabled}
                      checked={value === shareholder._id}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <p className="text-sm text-[#525869]">
          Want to Add another “Allowed user”?{" "}
          <Link
            className="font-bold"
            to={routes.protected.compliance.shareholders.link("fund-wallet")}
          >
            Start KYC here
          </Link>
        </p>
      </li>
    </ul>
  );
}

export default ShareholdersWithKyc;
